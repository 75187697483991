import PropTypes from 'prop-types'

import styles from './SingleColumn.module.scss'
import Container from '../../containers/Container'

const SingleColumn = ({ children, className = '', containerOptions = {}, id }) => {
  return (
    <Container {...containerOptions} id={id}>
      <section className={`ds-single-column mx-auto ${className}`}>{children}</section>
    </Container>
  )
}

SingleColumn.propTypes = {}

export default SingleColumn
